.content
  margin-left: auto
  margin-right: auto    
  padding: 2rem

.cardContainer
  background: white
  border-radius: 8px
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1)

.cardContainerContent
  padding: 1.5rem

.cardContainerContentTitle
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 1.5rem

.cardContainerContentTitleText
  font-size: 1rem
  font-weight: 500
  font-family: "Noto Sans TC", sans-serif

.confirmModal
  button
    border-radius: 0.5rem 
