@import  ../styles/_variables
  
html
  font-size: 16px

body
  padding: 0
  margin: 0
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
  background: #ffffff 
  font-size: 16px 
  font-family: 'SF Pro Display', sans-serif


// h1
//   margin: 0

a
  color: inherit
  text-decoration: none

* 
  box-sizing: border-box