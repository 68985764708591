@import  "../../../styles/_variables"
@import  "../../../styles/_mixins"
  
  
.container
  widows: 100
  height: calc( (100vh + 20px) - $header-height - $footer-height  )
  // background: #F7F7F7
  display: flex
  flex-direction: column


.contents
  padding: 2.5%
  label
    font-weight: 500
    font-size: 17px
  input
    padding: 13px
    border-radius:6px
    font-size: 17px
    font-weight: 300  
.memberText
  font-size: 17px
  font-weight: 300


.modalContainer
  :global(.ant-modal-close-x)
    position: relative
    top: -13px
    left: 13px
  :global(ant-modal-body)
    display: flex
    width: 100%
    background: #ff0000


.cardContainer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  


.payBoadContainer
  display: flex
  width: 100%
  height: 100%
  .payBoadItem:last-child
    margin-right: 0rem
  +rwd-gt(tablet)
    flex-direction: column
    .payBoadItem:last-child
      margin-bottom: 0rem


.payBoadItem
  width: 300px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-right:  1rem
  padding: 1rem 0.5rem
  border: 2px solid #f0f0f0
  border-radius: 6px
  +rwd-gt(tablet)
    margin-right:  0rem
    margin-bottom: 1rem
    
.payNameTitle
  width: 100%
  height: 100%
  font-size: 24px
  text-align: center

.payTitle
  width: 100%
  height: 100%
  font-size: 20px
  text-align: left

.payCurrency
  width: 100%
  height: 100%
  font-size: 14px
  text-align: left

.payMoney
  width: 100%
  height: 100%
  font-size: 48px
  color: #00b6ce
  text-align: center

.paySubmmit
  width: 100%
  height: 100%
  font-size: 16px
  button
    width: 100%
    height: 100%

.dolor
  font-size: 14px
  color: #000000

.payBtn
  button
    border-radius: 8px


