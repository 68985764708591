@import '../../styles/_variables'
@import '../../styles/_mixins'
  
.root
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  padding: 0% 10%
  height: auto
.title
  // margin: 1rem 0rem
  text-align: center

.cardContainer
  display: flex
  height: 100%
  // background: #bfbfbf
  padding: 2rem
  border-radius: 5px
  margin-bottom: 1rem
  :global(.ant-tabs-nav-list)
    div
      width: 100px
      text-align: center
  :global(.ant-tabs-card .ant-tabs-content )
    min-height: 50vh
    overflow: scroll
    margin-top: -16px
    background: #fff
    font-size: 20px
    border-radius: 5px
    :global(.ant-tabs-tabpane )
      padding: 16px
      background: #fff
    :global(.ant-tabs-nav::before )
      display: none

.buttonBar
  display: flex
  button
    margin-right: 1rem
    width: auto
    height: auto
    font-size: 1.5rem
    border-radius: 5px

.container
  margin: 1rem
  background: #ffffff
  width: calc( 100vw - $sidebar-width - 4rem )
  +rwd-gt(mobile)
    width: calc( 100vw  - 2.3rem )
  display: flex
  flex-direction: column
  // padding: 2% 0

    
.tab
  :global(.ant-tabs-nav)
    margin: 0 

.previewBlock
  overflow: auto
  flex: 1
  height: 100%
  +rwd-gt(mobile)
    margin-top: 1rem
    // display: none

.preview
  width: 4000px
  height: 100%
  background: #849ebf   !important