.container
  margin-bottom:10px
 
.button
  cursor: pointer
  font-size: 1rem
  height: auto
  span
    display: flex
    justify-content: center
    color: #ff0000

.content
  display: flex
  justify-content: center
  align-items: center

.col
  width: 100%
  margin-right: 10px

.formItem
  label
    color: #3398CB