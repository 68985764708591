.header 
  background: white
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1)
  height: auto
  padding: 0

.headerContent  
  margin-left: auto
  margin-right: auto
  padding: 1rem

.headerContentTitle 
  display: flex
  align-items: center
  gap: 0.5rem
  h4
    margin: 0

.headerContentTitleIcon 
  font-size: 1.5rem
  color: #3398CB

.headerContentTitleText 
  margin: 0
