@import  '../../../styles/_variables'
@import  '../../../styles/_mixins'
  
.container
  width: calc( 100vw -  $sidebar-width - 4rem  )
  +rwd-gt(mobile)
    width: calc( 100vw  - 4rem  ) 

.form
  width: 100%
  label
    height: 100%
    font-size: 17px
    margin-bottom: 14px
  input
    border-radius: 6px
    padding: 12px
    border: 0.5px solid #707070
    font-weight: 300
    

.modal
  :global(.ant-modal-body)
    width: 100%
    height: auto
    color: red
  :global(.ant-input)
    width: 100vw
    min-height: 60vh

.col
  padding-bottom: 10px

.button
  margin-bottom: 10px
  padding: 8px
  background: #3398CB
  border-radius: 8px


.phoneNum
  width: 100%
.link 
  color: #3398CB

.formHelp
  font-size: 9px
.input
  padding: 12px
  border-radius: 6px
  font-weight: 300  
  border: 0.5px solid #707070
  
.label
  color: #3398CB

.colorPickerlabel
  padding-bottom: 14px
  font-size: 17px
  label
    color: #3398CB



.titleSetting
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 1rem
  font-size: 15px
  input
      padding: 8px
      font-weight: 300
  label
    font-size: 13px
  :global(.ant-col)
    padding: 0px
  :global(.ant-form-item)
    margin: 0px  

  .titleFontSize
    flex: 1
    margin-right: 10px
  .titleColorPicker
    flex: 1

.textSetting
  margin-bottom: 10px  
  @extend  .titleSetting




.buttonWord
    display: flex
    width: 100%
    height: 100%
    justify-content: center
    align-items: center

.textlable
  margin-bottom: 14px
  label
    color: #3398CB

 
.addButtonContainer
  display: flex
  div
    margin-right: 10px

.previewBlock
  overflow: auto


.preview
  width: 4000px
  height: 100%
  background: #849ebf

