@import  "../../../styles/_variables"
@import  "../../../styles/_mixins"
  
.container
  column-count: 4
  column-gap: 1.5rem
  padding: 1rem
  +rwd-gt(tablet)
    column-count: 2
  +rwd-gt(mobile) 
    column-count: 1

.listContainer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  box-shadow: 0px 0px 10px #00000026
  border-radius: 6px
  width: 100%
  height: auto
  margin-bottom: 1.5rem
  break-inside: avoid

.listTitle
  width: 100%
  padding: 15px
  border-bottom: 0.5px solid #e0dcdc
  font-size: 17px
  letter-spacing: 0.34px
  white-space: nowrap // 防止文字折行 
  overflow: hidden // 隱藏超出範圍的文字 
  text-overflow: ellipsis // 顯示省略號 

.columnTitle
  width: 100%
  padding: 6px
  border-bottom: 0.5px solid #e0dcdc
  font-size: 15px
  letter-spacing: 0.34px

.changelog
  display: flex
  flex-direction: column
  padding: 6px
  width: 100%
  font-size: 15px

.changelogCol
  display: flex
  justify-content: space-between
  width: 100%
  
.date
  width: 100%
  padding: 15px
  border-bottom: 0.5px solid #e0dcdc
  font-size: 15px
  letter-spacing: 0.34px

.time
  color: #999999

  