@import  '../../styles/_variables'
@import  "../../styles/_mixins"

:global(.ant-layout-header)
  padding: 0px 20px
:global(.ant-dropdown)
  width: 100%
  :global(.ant-dropdown-menu-title-content)
    font-size: 1.1rem
  

.Header
  height: 100%  
  background:  #FFFFFF
  display: flex
  justify-content: space-between
  border: 1px  solid #f5f3f3
  border-left: none
  border-right: none
  position: relative


.NavBar
  margin-left: 10px
  color: #f0f0f0
  cursor: pointer

.col
  display: flex
  align-items: center
  


.login
  flex: 1
  width: 55px
  height: 55px
  border-radius: 50%
  svg
    width:24px
    height: 24px
    // background: red


.button
  width: 90px
  height: 42px
  text-align: center
  letter-spacing: 0.3px
  opacity: 1
  font-size: 15px
  border: none
  border-radius: 8px
  background: #3398CB
  cursor: pointer
.buttonWord
  display: flex
  width: 100%
  height: 100%
  justify-content: center
  align-items: center

.cancel
  margin-right: 1rem
  font-size: 15px
  cursor: pointer

.createNameCardBtn
  margin-right: 1rem
  button
    border-radius: 8px
    font-size: 15px


.navBar
  display: flex
  align-items: center

.profileBtn
  cursor: pointer

.nameCardsListHeader
  display: none
  +rwd-gt(tablet-sm)
    display: block


.nav__toggler
    display: none
    +rwd-gt(tablet-sm)
        display: block
        cursor: pointer
        // position: fixed
        right: 0.5rem
        z-index: 12
        top: 0.5rem

.nav__toggler 
    div 
        width: 1.5rem
        height: 0.2rem
        margin: 0.4rem
        background: #000000
        transition: 0.3s ease-in
        border-radius: 10px

.navbar
    padding: 0 20%
    display: flex
    box-shadow: 0px 0px 10px #00000029
    // border: 1px solid #f5f3f3
    // margin-top: -.5rem
    // height: $nofooter-height
    +rwd-gt(tablet-sm)
        flex-direction: column
        align-items: center
        justify-content: start
        position: fixed
        top:  0
        right: 0
        height: 100vh
        width:50vw
        background:  #F9FBFC
        z-index: 11
        padding: 0
        transform: translateX(100%)
        transition: 0.3s ease-in
        margin: 0
        padding-top: calc(  $header-height - 1rem  ) 
        border: 1px solid #f5f3f3

@include rwd-gt(tablet-sm) 
    .navbar__active
        transform: translateX(0%)

          /* Toggle Icon Animation */
    .toggle
      position: relative
      right: 100px
      div
        height: 0.3rem
        width: 1.2rem
    .toggle .line1 
        transform: rotate(45deg) translate(0px, 0px)

    .toggle .line2 
        opacity: 0

    .toggle .line3 
        transform: rotate(-45deg) translate(8px, -9px)


.text
    color: #8B8E93
    position: relative
    cursor: pointer
    opacity: 0.8
    white-space: nowrap
    box-shadow: inset 0px -0.4px 0px #00000029
    background: #FFFFFF
    width: 100%
    text-align: center
    border-top: 1px solid #f5f3f3
    display: flex
    justify-content: center
    align-items: center
    .label
      +rwd-gt(tablet-sm)
        font-size: 1rem
        flex: 1

.buttonDefault 
  cursor: pointer
  // margin-top: 3px
  padding: 0 6px
  display: flex
  align-items: center
  justify-content: center
  img
    width: 30px
    height: 30px
  svg
    width: 28px
    height: 28px

.selectColor
  color: #3398CB

.nomalColor
  color: #8b8e93
    

