@import  '../../styles/_variables'
@import  "../../styles/_mixins"
  
  
.sideBarContainer
  display: flex
  flex-direction: column
  align-items: center
  border: 1px solid #f5f3f3
  min-width: $sidebar-width
  li
    list-style: none
  ul
    margin: 0
    padding: 0
  +rwd-gt(tablet-sm)
    display: none

.hasFoorerHeaderHeight
  min-height: $has-foorer-header-height
  
.noFoorerHeight
  min-height: $nofooter-height



.buttonDefault 
  margin: 10px 0px
  cursor: pointer
  img
    width: 32px
    height: 32px
  svg
    width: 30px
    height: 30px

.selectColor
  color: #3398CB

.nomalColor
  color: #8b8e93
  




