@import  "../../../styles/_variables"
@import  "../../../styles/_mixins"
  
.container
  column-count: 3
  column-gap: 1.5rem
  padding: 1rem
  +rwd-gt(tablet)
    column-count: 2
  +rwd-gt(tablet-sm) 
    column-count: 1

.deleteModal,.assignModal
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 1rem
  .button
    border-radius: 8px
    font-size: 15px
    margin-right: 10px
  .input
    width: 100%

.buttonWord
  display: flex
  width: 100%
  height: 100%
  justify-content: center
  align-items: center

.formLabel
  font-weight: 600

.formInput
  width: 100%
  input
    height: 42px
    border-radius: 8px
    width: 100%

.friendsLabel
  font-weight: 600
  margin-bottom: 0.5rem

.inputWithRemove
  display: flex
  gap: 1rem
  justify-content: center
  align-items: center
  font-size: 1rem


.collapse
  width: 100%

.collapseHeader
  font-weight: 600

.assignModalContent
  width: 100%

.addFriendButton
  width: 100%
  border: 1px dashed #90caf9
  border-radius: 0.375rem
  padding: 0.5rem
  text-align: center
  color: #2196f3
  cursor: pointer
  transition: background-color 0.3s
  .button
    width: 100%
    height: 100%
  
  &:hover
    background-color: #e3f2fd

.table
  overflow-x: auto


