@import '../../../styles/_variables'
@import '../../../styles/_mixins'
    
.container
  background: #f5f5f5
  padding: 1rem
  border-radius: 1rem
  margin-bottom: 1.5rem
  display: flex
  +rwd-gt(mobile)
    flex-direction: column
  gap: 1rem



.space
  width: 100%

.select
  flex: 1 1 0
  margin-bottom: 0
  max-width: 20rem

.divider
  margin: 0.5rem 0

