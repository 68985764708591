.termsContainer 
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.termsContent
  width: 100%
  height: 100%
  background-color: #fff
  padding: 20px
  box-sizing: border-box

