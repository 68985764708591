@import '../../styles/_variables'
  
.container
  display: flex
  flex-direction: column
  background: #FFFFFF

.bodyContainer
  display: flex
  flex-direction: row
  height: $nofooter-height
  width: 100%

.body 
  display: flex
  flex-direction: column
  // justify-content: center
  width: 100%


.main
  // flex: 1
  overflow: auto
  height: $has-foorer-header-height
  
.mainNoFoorerHeight
 flex: 1
 overflow: auto
 height: $nofooter-height

  

  

