@import  "../../styles/_variables"
@import  "../../styles/_mixins"
  
.container
  display: flex
  justify-content: center
  align-items: center
  padding: 1rem
  height: calc( (100vh  - $header-height - $footer-height  ))
  width: 100%
  flex-direction: column


.res 
  margin-top: 1rem