@import ../styles/_rem_calc

$header-height: rem_calc(80px)
$header-background: #3b7ba7
$footer-height: rem_calc(80px)
$has-foorer-header-height: calc( 100vh - ($header-height + $footer-height) )
$sidebar-width: rem_calc(90px)
$nofooter-height: calc( 100vh - $header-height  )
$footer-background: #fafafa
$button-disabled-background: #d9d9d9
$button-active-background: #3b7ba7
$theme: #f0f0f0
$flex-direction: (row: row, row-r: row-reverse, column: column, column-r: column-reverse)
$justify-content: (start: flex-start, center: center, end: flex-end, between: space-between, around: space-around)
$align-items: (start: flex-start, center: center, end: flex-end, between: space-between, around: space-around)

$breakpoints: (mobile: 500px, tablet-sm: 768px, tablet: 1024px, desktop: 1360px)


