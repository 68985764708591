

.picker 
  position: relative
  display: flex
  align-items: center
  input
    width: 100%
    border: 0.5px solid #707070



.swatch 
  width: 28px
  height: 28px
  border-radius: 8px
  border: 3px solid #fff
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)
  cursor: pointer
  position: absolute
  left:-50px
  top: -13.5px


.popover 
  position: absolute
  top: -200px
  right: 0
  border-radius: 9px
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15)

.colorSelect
  position: relative


