.formItem 
  width: 100%

.inputContainer
  display: flex
  height: 42px
  gap: 0.5rem

.input
  border-radius: 0.5rem
  height: 100%

.button
  border-radius: 0.5rem
  height: 100%
