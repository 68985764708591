@import  ../../../styles/_variables
@import  '../../../styles/_mixins'
  
.root
  display: flex
  justify-content: right
  align-items: center
  background: #ffffff
  text-align: center
  min-height: $footer-height
  border: 1px  solid #f5f3f3
  width: calc( 100vw - $sidebar-width )
  +rwd-gt(mobile)
    width: 100vw








:global(.ant-layout-header)
  padding: 0px 20px
:global(.ant-dropdown)
  width: 100%
  :global(.ant-dropdown-menu-title-content)
    font-size: 1.1rem
  


.NavBar
  margin-left: 10px
  color: #f0f0f0
  cursor: pointer

.col
  display: flex
  align-items: center
  


.login
  flex: 1
  width: 55px
  height: 55px
  border-radius: 50%
  svg
    width:24px
    height: 24px
    // background: red


.button
  width: 90px
  height: 42px
  text-align: center
  letter-spacing: 0.3px
  opacity: 1
  font-size: 15px
  border: none
  border-radius: 8px
  background: #3398CB
  cursor: pointer
.buttonWord
  display: flex
  width: 100%
  height: 100%
  justify-content: center
  align-items: center

.cancel
  margin-right: 1rem
  font-size: 15px
  cursor: pointer
  color: #3398CB


  
.send
  width: 90px
  height: 42px
  text-align: center
  letter-spacing: 0.3px
  opacity: 1
  font-size: 15px
  border: none
  border-radius: 8px
  background: #F5A350
  cursor: pointer


  

.createNameCardBtn
  margin-right: 1rem
  button
    border-radius: 8px
    font-size: 15px


.navBar
  display: flex
  align-items: left

.profileBtn
  cursor: pointer

.buttonItem
  margin-right: 1rem
  .sendButton
    :global(.ant-btn-primary:hover)
      background:  #f3b475
  .cancelButton
    :global(.ant-btn-primary:hover)
      background: #AAA


.shareButtonDisable
  background: #fde1c5

.buttonGreyBg
  background: #AAA