.container
   display: flex,
   flex-direction: column
   justify-content: center
   align-items: center
.buttonContainer
  width: 300px
  height: 60px
  .button
    width: 100%
    height: 100%
    borderRadius: 6px
.textareaContainer
  width: 80%



