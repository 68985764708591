.content 
  display: flex
  align-items: center
  flex: 1
  gap: 1rem


.col
  display: flex
  flex: 1
  gap: 1rem
  align-items: center
  div
    flex: 1
.button
  cursor: pointer
  font-size: 1rem
  height: 100%
  span
    display: flex
    justify-content: center
    color: #ff0000