@import  "../../styles/_variables"
@import  "../../styles/_mixins"
  
  
.container
  width: 100%
  height: calc( (100vh  - $header-height - $footer-height  ))
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  :global(.ant-card ant-card-bordered)
    background: #f0f2f5


.button
  text-align: center
  letter-spacing: 0.3px
  opacity: 1
  font-size: 15px
  border: none
  background: #3398CB
  cursor: pointer
  .buttonWord
    display: flex
    width: 100%
    height: 100%
    justify-content: center
    align-items: center
  button
    border-radius: 8px
    font-size: 15px