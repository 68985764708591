@import  "../../../../styles/_variables"
@import  "../../../../styles/_mixins"
  
.filters 
  display: flex
  align-items: center
  width: 100%
  height: 5rem
  +rwd-gt(tablet-sm)
    height: 10rem
  padding: 0.5rem
  background: #fafafa
  gap: 0.5rem

.filtersSpace
  display: flex
  align-items: center
  +rwd-gt(tablet-sm)
    flex-direction: column

.filtersInput
  width: 200px
  input
    border-radius: 0.5rem
    height: 100%

.filtersSelect
  width: 200px
  input
    border-radius: 0.5rem
    height: 100%
.filtersIcon
  margin-right: 0.5rem
  width: 1rem
  height: 1rem

.filtersButtonContainer
  display: flex
  align-items: center
  +rwd-gt(tablet-sm)
    flex-direction: column
  button
    border-radius: 0.5rem



