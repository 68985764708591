.container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-top: 1rem



.content
  min-width: 350px
  min-height: 300px
  font-size: 1.1rem
  padding: 1rem
  svg
    font-size: 1rem

  