.categoryList
    margin-top: 0.5rem

.categoryItem
    border-radius: 0.5rem
    padding: 1rem
    border: 1px solid #e0e0e0
    margin-bottom: 0.5rem

.categoryItemHeader
    display: flex
    align-items: center
    justify-content: space-between

.categoryItemName
    display: flex
    align-items: center
    margin-left: 0.5rem
    flex: 1 1 0%
    cursor: pointer
    gap: 0.5rem

.categoryItemIcon
    font-size: 1.5rem
    color: #3398CB

.categoryItemNameText
    font-weight: 500
    color: #111827

.categoryItemIconRight
    font-size: 1.5rem
    color: #3398CB
    transition: transform 0.1s ease-in-out
    transform: rotate(0deg)
    &.rotate 
        transform: rotate(90deg)

.categoryItemActions
    display: flex
    align-items: center
    margin-left: 0.5rem

.folderForm
    margin-top: 1rem
    padding-left: 1.5rem

.folderList
    margin-top: 0.5rem

.folderListItem
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0.5rem
    cursor: pointer
    &:hover
        background-color: #f0f0f0
        border-radius: 6px

.folderListItemName
    display: flex
    align-items: center
    gap: 0.5rem

.folderIcon
    font-size: 1rem
    color: #6b7280

.folderListItemNameText
    color: #292c33

.folderListItemActions
    display: flex
    align-items: center
    gap: 0.5rem



