@import  "../../styles/_variables"
@import  "../../styles/_mixins"
    
.container
  display: flex
  justify-content: center
  align-items: center
  padding: 1rem
  height: calc( (100vh  - $header-height - $footer-height  ))
  width: 100%
  flex-direction: column
  gap: 1rem
  

.card
  height: 100%
  width: 100%
  max-width: 600px

.registerPartnersAccountPageForm
  width: 100%

.buttonContainer
  display: flex
  justify-content: center
.button
  border-radius: 0.5rem
  height: 42px
  max-width: 380px
  width: 100%