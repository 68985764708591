@import "../../styles/variables.sass"
@import "../../styles/mixins.sass"

.container 
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  padding: 1rem
  max-height: calc((100vh  - $header-height - $footer-height  ))
  overflow: auto
  width: 100%

.card
  width: 100%
  max-width: 600px
  height: 100%

.title
  text-align: center
  margin: 0