@import "../../../styles/variables.sass"
@import "../../../styles/mixins.sass"    

.categoryForm
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 1rem
  margin-left: 0.5rem
  +rwd-gt(tablet-sm)   
    flex-direction: column
  input
    border-radius: 8px

.categoryFormButtonContainer
  display: flex
  gap: 10px

.categoryFormButtonContainerItem
  button
    border-radius: 8px