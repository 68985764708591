.card
  width: 100%
  max-width: 400px

.formItem
  width: 100%
  display: flex
  gap: 1rem

.inputWrapper
  width: 100%
  input
    width: 100%
    height: 42px

    
.button 
  display: flex
  align-items: center
  justify-content: center
  padding: 0 4px 
  width: 100%
  height: 100%
  padding: 0.5rem
  border-radius: 0.5rem

.authCodeInput
  width: 100%
  input
    width: 100%
    height: 52px
    border-radius: 0.5rem