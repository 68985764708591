.modalStyle
  :global(.ant-modal-content)
    border-radius: 10px 
  :global(.ant-modal-header)
    border-radius: 10px
.footerControl
  display: flex
  justify-content: flex-end
  .cancelButton
    :global(.ant-btn-primary:hover)
      background: #bcb8b8

.footerButtonItem
  margin-right: 1rem



.footerButton
  width: 80px
  height: 42px
  text-align: center
  letter-spacing: 0.3px
  opacity: 1
  font-size: 12px
  border: none
  border-radius: 8px

.buttonBlueBg
  background: #3398CB
.buttonBlueColor
  color: #3398CB


.buttonWord
  display: flex
  width: 100%
  height: 100%
  justify-content: center
  align-items: center

.modalTitle
  width: 100%
  text-align: center

.contentContainer
  display: flex
  justify-content: center
  align-items: center
  img
    width: 100%

.imgItem
  margin-right: 1rem

.nameCardTypeItem
  display: flex
  flex-direction: column
.nameCardTypeItemText
  margin-top: 1rem
  text-align: center
.selected 
  color: #3398CB
  img
    border: 2px solid #3398CB


.buttonGreyBg
  background: #AAA