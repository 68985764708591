.cardContainer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  box-shadow: 0px 0px 10px #00000026
  border-radius: 6px
  width: 100%
  height: auto
  margin-bottom: 1.5rem
  break-inside: avoid

.coverImgContainer
  width: 100%
  height: auto
  position: relative
  .coverImg
    width: 100%
    height: 100%
    min-height: 285.6px  
    object-fit: cover  
  img
    width: 100%
    height: 100%


.buttonBar
  position: absolute
  top: 0px
  right: 10px

.buttonBarCol
  width: 36px
  height: 36px
  margin-top: 8px
  padding: 2.5px
  border-radius: 8px
  cursor: pointer
  img
    width: 100%
    height: 100%
    object-fit: contain
  

.buttonDefault
  @extend  .buttonBarCol
  border:  2px solid #3398CB

.buttonDelete
  @extend  .buttonBarCol
  border:  2px solid #F5A350

.cardTitle
  width: 100%
  padding: 15px
  border-bottom: 0.5px solid #e0dcdc
  font-size: 17px
  letter-spacing: 0.34px
  white-space: nowrap  ///防止文字折行 
  overflow: hidden //隱藏超出範圍的文字 
  text-overflow: ellipsis //  顯示省略號 

.changelog
  display: flex
  flex-direction: column
  padding: 10px
  width: 100%
  font-size: 13px

.changelogCol
  display: flex
  justify-content: space-between
  width: 100%

.time
  color: #999999

.assignButton
  @extend .buttonDefault
  border: 2px solid #3398CB
  margin-top: 8px
  padding: 2.5px
  border-radius: 8px
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  svg
   width: 21px
   height: 21px
