.planModal 
  .planCards 
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
    gap: 1rem
    margin-bottom: 1.5rem
    .planCard 
      cursor: pointer
      transition: all 0.3s
      &.selected 
        border-color: #1890ff
        background-color: #e6f7ff
      .price 
        font-size: 1.5rem
        color: #1890ff
        font-weight: bold
      .selectionStatus 
        position: absolute
        bottom: 12px
        right: 12px
        font-size: 12px
        color: #666
        padding: 4px 8px
        border-radius: 4px
        background-color: #f5f5f5
        transition: all 0.3s
        &.selected 
          background-color: #1890ff
          color: white 
  .termsContainer 
    margin-bottom: 1.5rem
    .termsContent 
      height: 200px
      overflow-y: auto
      padding: 1rem
      border: 1px solid #d9d9d9
    //   border-radius: 0.5rem
      margin-bottom: 1rem


.confirmationModal 
  .planInfo 
    background-color: #f5f5f5
    padding: 1rem
    border-radius: 0.5rem
    margin-top: 1rem
    .price 
      font-size: 1.25rem
      color: #1890ff
      font-weight: bold
      margin-top: 0.5rem
.paymentModal 
  .paymentInfo 
    padding: 24px
    background-color: #f5f5f5
    border-radius: 8px
    > div 
      margin-bottom: 12px
      font-size: 16px
      &:last-child 
        margin-bottom: 0
    .paymentAction 
      margin-top: 24px
      text-align: center
      button 
        border-radius: 0.5rem

.buttonContainer
    display: flex
    justify-content: flex-end
    gap: 0.5rem
    margin-top: 1rem
    button 
     border-radius: 0.5rem
.checkbox
    margin-top: 0.5rem
    margin-bottom: 1rem
