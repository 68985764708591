@import "../../../styles/mixins.sass"
@import "../../../styles/variables.sass"
    
.folderForm
    display: flex
    align-items: center
    gap: 0.5rem
    margin-bottom: 0.5rem
    +rwd-gt(tablet-sm)   
        flex-direction: column
    input
        border-radius: 0.5rem
        width: 200px


.folderFormButtonContainer
    display: flex
    gap: 0.5rem

.folderFormButtonContainerItem
    button
        border-radius: 0.5rem
