@import  '../../styles/_variables'
@import '../../styles/_mixins'
  
.container
  width: calc( 100vw  - $sidebar-width ) 
  display: flex
  +rwd-gt(mobile)
    width: 100vw
    flex-direction: column
  label
    height: 100%
    font-size: 14px
    margin-bottom: 14px
  input 
    border-radius: 6px
    padding: 12px
    border: 0.5px solid #707070
    font-weight: 300
    width: 100%
  input::-webkit-outer-spin-button , input::-webkit-inner-spin-button
    -webkit-appearance: none
    -webkit-appearance
    inpt[type="number"]
      -moz-appearance: textfield
    //  :global(.inpt[type="number"])

  .selectInputItem
    :global(.ant-select-selector)
      height: 50px
      border-radius: 6px
      border: 0.5px solid #707070
      font-weight: 300
      font-size: 14px
      padding: 0.5rem

.controlBar
  height: $has-foorer-header-height
  width: 100%
  padding: 2%
  +rwd-gt(mobile)
    height: auto
    display: none
  
.formMobile
  width: 100%
  +rwd-gt(mobile)
    display: block
    height: auto
    width: 100%
    padding: 2%
    order: 1


.controlBarMobile
  display: none
  +rwd-gt(mobile)
    display: block
    height: auto
    width: 100%
    padding: 2%
    order: 1

  
.previewBlock
  border: 1px solid #f5f3f3
  overflow: auto
  position: relative
  height: 100%
  overflow:  auto
  +rwd-gt(mobile)
    margin-top: 1rem
    margin-bottom: 2rem
    order: 2

.mobileControl
  display: none
  +rwd-gt(mobile)
    display: flex,
    flex-direction: column,
    position: fixed,
    font-size: 1.5rem,
    width: auto,
    height: auto,
    top: $header-height
    right: 3px,
    opacity:0.7
    z-index: 10
    button
      border-radius: 8px


.buttonItem
  margin-right: 1rem

.defaultButton
  width: auto
  height: 42px
  text-align: center
  letter-spacing: 0.3px
  opacity: 1
  font-size: 12px
  border: none
  border-radius: 8px
  background: #3398CB
  cursor: pointer

.buttonWord
  display: flex
  width: 100%
  height: 100%
  justify-content: center
  align-items: center

.uploadButton
  border-radius: 8px

  
.flexRow
  display: flex
  flex-direction: row
  width: 100%
  align-items: center
  margin-bottom: 1.5rem
  .flexCol:last-child
    margin-right: 0rem
  
.flexCol
  margin-right: 1rem

.selectInputItem
  font-weight: 300
  width: 100%
  height: 100%

.formItem
  width: 100%

.defaultButtonContainer
  width:  20% 
  height: 50px
  button
    width: 100%
    height: 100%

.pageEditer
  width: 100%
  height: 100%
  border: 0.5px solid #707070
  border-radius: 6px

.pageEditerMobile
  display: none
  +rwd-gt(mobile)
    display: block
    width: 100%
    height: 100%
    border: 0.5px solid #707070
    border-radius: 6px
    order: 3
    padding: 2%

  

.pagepadding
  padding: 3%
  

.pageEditerTitle
  display: flex
  border-bottom: 1px solid  #707070
  justify-content: space-between
  padding: 1% 3%
  font-size: 12px
  background: #F9FBFC

.buttonEditBlock
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  .flexCol:last-child
    margin-right: 0rem

.addButtonBlock
  display: flex
  align-items: center


.deleteButton
  width: auto
  height: 42px
  text-align: center
  letter-spacing: 0.3px
  opacity: 1
  font-size: 12px
  border: none
  border-radius: 8px
  cursor: pointer

.originButton
  background: #F5A350
.originButtonHover
  :global(.ant-btn-primary:hover)
    background:  #f3b475

.deleteButtonBlock
  :global(.ant-btn-primary:hover)
    background:  #f3b475

.buttonItem
  margin: 1rem

.flexMsgButtonItem
  display: flex
  align-items: center
  height: 100%
  width: 100%



.cardTitle
  height: 100%
  margin-bottom: 17px
  font-size: 17px

.buttonBlock
  display: flex

.modalButton
  width: 50px
  height: 42px
  text-align: center
  letter-spacing: 0.3px
  opacity: 1
  font-size: 12px
  border: none
  border-radius: 8px

.modalButtonBlue
  background: #3398CB

.modalStyle
  :global(.ant-modal-content)
    border-radius: 10px !important
.deleteFlexButton
  cursor: pointer
  font-size: 1rem
  height: auto
  span
    display: flex
    justify-content: center
    color: #ff0000